<div
  class="d-flex align-items-center justify-content-between c-wrapperbox dotted"
  *ngIf="validMode"
>
  <div class="col-3 px-0 pr-4">
    <div class="mx-auto mr-lg-3">
      <div class="text-center mx-auto">
        <div>
          <i class="fa commute_icon" [ngClass]="commuteIcon"></i>
        </div>
        <div class="extra-small-heading text-uppercase secondary-text-color">
          {{ commuteType }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-9 px-0 pr-4">
    <div class="row align-items-center px-lg-0 pl-4 justify-content-between">
      <span class="small-heading"> About {{ commuteTime.text }} </span>
      <span class="local_commute_options" *ngIf="direction !== ''">
        <a [href]="direction" target="_blank">
          <i class="fa fa-directions primary-text-color"></i>
        </a>
      </span>
    </div>
  </div>
</div>
