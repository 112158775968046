import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-day-commute',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './day-commute.component.html',
  styleUrls: ['./day-commute.component.css'],
})
export class DayCommuteComponent implements OnInit {
  @Input() commute: any;

  commuteType: string = 'drive';
  commuteTime: any = { text: '', value: 0 };
  commuteIcon: string = 'fa-slash';
  direction: string = '';
  validMode: boolean = false;
  constructor() {}

  ngOnInit() {
    this.checks();
  }

  checks() {
    console.log('MODE --------------------------------');
    console.log(this.commute);
    this.validMode = false;
    if (this.commute['data']['transport_mode'] != '') {
      this.validMode = true;
    }

    if (this.commute.hasOwnProperty('data')) {
      this.commuteType = this.commute.data.hasOwnProperty('transport_mode')
        ? this.commute.data['transport_mode'].toLowerCase()
        : 'drive';
      if (this.commute.data.hasOwnProperty('duration')) {
        this.commuteTime = this.commute.data['duration'];
        this.commuteTime.text = this.commute.data['duration']['text'];
      }
    }
    let mode: string = '';

    const commuteMapping: any = {
      flight: { icon: 'fa-plane-departure', mode: 'flying' },
      car: { icon: 'fa-car', mode: 'driving' },
      bike: { icon: 'fa-motorcycle', mode: 'driving' },
      walk: { icon: 'fa-walking', mode: 'walking' },
      walking: { icon: 'fa-walking', mode: 'walking' },
      tube: { icon: 'fa-subway', mode: 'public' },
      train: { icon: 'fa-train', mode: 'public' },
      bus: { icon: 'fa-bus', mode: 'public' },
      'public transport': { icon: 'fa-bus', mode: 'public' },
      'public transit': { icon: 'fa-bus', mode: 'public' },
      taxi: { icon: 'fa-taxi', mode: 'driving' },
      metro: { icon: 'fa-subway', mode: 'transit' },
      ferry: { icon: 'fa-ship', mode: 'public' },
      boat: { icon: 'fa-ship', mode: 'public' },
      bicycle: { icon: 'fa-bicycle', mode: 'walking' },
      tram: { icon: 'fa-tram', mode: 'tram' },
    };

    const defaultCommute = { icon: 'fa-slash', mode: 'driving' };

    const commuteDetails = commuteMapping[this.commuteType] || defaultCommute;

    this.commuteIcon = commuteDetails.icon;
    mode = commuteDetails.mode;

    if (this.commute.hasOwnProperty('places')) {
      this.googleDirection(mode);
    }
  }

  secondsToHHMM(seconds: number): string {
    const hours: number = Math.floor(seconds / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);
    const hour_text =
      hours != 0 ? hours.toString().padStart(2, '0') + ' hours' : '';
    const min_text = minutes.toString().padStart(2, '0') + ' mins';
    return hour_text + ' ' + min_text;
  }

  googleDirection(mode: string) {
    const srcLatLong = this.commute['places']['source']['locationString'];
    const destLatLong = this.commute['places']['dest']['locationString'];
    this.direction =
      'https://www.google.com/maps/dir/?api=1&origin=' +
      srcLatLong +
      '&destination=' +
      destLatLong +
      '&travelmode=' +
      mode;
  }
}
