import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
  DoCheck,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  inject,
  TemplateRef,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { GeneralService } from '../../core/general.service';
import { CookieServices } from '../../core/cookies.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { HubComponent } from './hub/hub.component';
import { HubModeComponent } from './hub-mode/hub-mode.component';
import { DayCommuteComponent } from './day-commute/day-commute.component';
import { ReferenceBlockComponent } from './reference-block/reference-block.component';
import { DayPlacesComponent } from './day-places/day-places.component';
import { FreeTimeComponent } from './free-time/free-time.component';
import { ImageComponent } from './image/image.component';
import { DayMapComponent } from './day-map/day-map.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { NotificationEvent } from './library/notifications-events';
@Component({
  selector: 'app-itinerary',
  standalone: true,
  imports: [
    CommonModule,
    HubComponent,
    HubModeComponent,
    DayCommuteComponent,
    ReferenceBlockComponent,
    DayPlacesComponent,
    FreeTimeComponent,
    RouterModule,
    ImageComponent,
    DayMapComponent,
  ],
  templateUrl: './itinerary.component.html',
  styleUrl: './itinerary.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [GeneralService, CookieServices, CookieService],
})
export class ItineraryComponent implements OnInit, OnChanges, DoCheck {
  @Input() tripurl: string = '';
  @Input() firsttrip: string = '';
  @Output() notification: EventEmitter<any> = new EventEmitter();
  @ViewChild('scrollBox') scrollBox!: ElementRef;

  @ViewChildren('dayElements') dayElements!: QueryList<ElementRef>;

  private modalService = inject(NgbModal);

  showTrip: boolean = false;
  tripTitle: string = '';
  tripStory: string = '';
  tripDays: any = [];
  tripDetails: any = { hubs: [], days: [{ days: [], details: {} }] };
  tripGenerated: boolean = false;
  boxWidth: number = 200;
  tripStatus: string = 'private';
  daysDropDown: boolean = false;
  itienraryView: string = 'list';
  editable: boolean = false;
  loadingText: any = { text: 'Loading Itinerary...', cssclass: '' };
  isBrowser: boolean = false;
  notificationStructure: any = {
    type: NotificationEvent,
    message: '',
    result: false,
  };
  imagesLength: number = 7;
  oneDay: any = [];
  trip: any;
  tripImages: any = [];
  loadingItinerary: boolean = true;
  allDaysHolder: any = [];
  private calculateBoxWidthCalled: boolean = false;
  private firstDayNumber: any = null;
  constructor(
    private generalService: GeneralService,
    private el: ElementRef,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes data');
    console.log(changes);
    if (changes['tripurl'] && changes['tripurl'].currentValue) {
      if (changes['tripurl'].previousValue != changes['tripurl'].currentValue) {
        console.log('tripurl changed:', this.tripurl);
        console.log('this.firsttrip');
        console.log(this.firsttrip);
        if (this.firsttrip && this.firsttrip == 'first') {
          this.generate(this.tripurl);
        } else {
          this.getTrip(this.tripurl);
        }
      }
    }
  }

  ngDoCheck() {
    console.log('DO CHECK...   ' + this.tripDays.length);
    console.log(this.tripDays);
    if (this.tripDays.length > 1 && !this.calculateBoxWidthCalled) {
      setTimeout(() => {
        this.calculateBoxWidth();
        this.calculateBoxWidthCalled = true;
        console.log('Days array has changed');
      }, 100);
    }
  }

  ngOnInit(): void {
    console.log('itinerary loaded  changes');
    console.log(this.tripurl);
    console.log('get Devices...');
    console.log(this.getDeviceType());
    if (this.getDeviceType() == 'mobile') {
      this.imagesLength = 1;
    } else {
      this.imagesLength = 2;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  checkEditable(currentUserId: string): void {
    const userDetail: any = this.generalService.getLoggedUser();
    if (userDetail) {
      if (userDetail['user_id'] && userDetail['user_id'] == currentUserId) {
        this.editable = true;
      }
    }
  }
  showDayMap(dayCount: number, dayNumber: string) {
    const modalRef = this.modalService.open(DayMapComponent, {
      fullscreen: true,
    });

    console.log('dayCount');
    console.log(dayCount);
    console.log(this.tripDays[dayCount]);
    modalRef.componentInstance.options = {
      dayNumber: dayNumber,
      dayTrip: this.tripDays[dayCount],
    };
  }
  scrollDays(direction: string) {
    const scroll = direction === 'left' ? this.boxWidth * -1 : this.boxWidth;
    this.scrollBox.nativeElement.scrollBy({ left: scroll, behavior: 'smooth' });
  }

  updateLoadingText(text: string, cssclass: string = '') {
    this.loadingText.text = text;
    this.loadingText.cssclass =
      cssclass == '' ? this.loadingText.cssclass : cssclass;
  }
  getDeviceType(): string {
    if (this.isBrowser) {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile|android|touch|webos|hpwos/i.test(userAgent)) {
        return 'mobile';
      }
      if (/ipad|tablet|playbook|silk|kindle/i.test(userAgent)) {
        return 'tablet';
      }
      return 'desktop';
    } else {
      return 'desktop';
    }
  }
  calculateBoxWidth() {
    if (this.isBrowser) {
      // Assuming all boxes have the same width
      const firstBox: HTMLElement = this.scrollBox.nativeElement
        .children[0] as HTMLElement;
      if (firstBox) {
        const style = window.getComputedStyle(firstBox);
        const margin =
          parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        this.boxWidth = firstBox.offsetWidth + margin;
      }
      console.log('this.boxWidth');
      console.log(this.boxWidth);
    }
  }

  getTripImages(place: any) {
    console.log('getTripImages');
    let image = '';
    let imgRat: number = 0;
    if (place['place_type'] !== 's') {
      if (place.hasOwnProperty('parent')) {
        if (place['parent'].hasOwnProperty('experience_media')) {
          const placeImages = place['parent']['experience_media'];
          let imgRate = 0;
          let imgPath: string = '';
          placeImages.forEach((img: any) => {
            console.log('imgRating check');
            console.log(img);
            if (img['active']) {
              if (img['imgRate'] > imgRate) {
                imgRate = img['imgRate'];
                imgPath = img['path'];
              }
            }
          });
          image = imgPath;
          imgRat = imgRate;
        }
      }  
      if (place.hasOwnProperty('image') && image=='') {
        image = place['image'];
      }
    }
    if (image != '') {
      this.tripImages.push({ img: image, imgRate: imgRat });
      this.tripImages.sort((a: any, b: any) => b.imgRate - a.imgRate);
    }
  }

  resetTripVariables() {
    this.trip = null;
    this.tripTitle = '';
    this.tripStory = '';
    this.loadingItinerary = true;
    this.tripDetails = { hubs: [], days: [{ days: [], details: {} }] };
    this.tripDays = [];
    this.allDaysHolder = [];
    this.calculateBoxWidthCalled = false;
    this.firstDayNumber = null;
    this.tripImages = [];
  }
  async generate(tripurl: string) {
    if (tripurl) {
      this.resetTripVariables();
      console.log('generating...');
      const apiUrl = 'trip/getInitialTrip/' + tripurl;
      const data = { url: apiUrl, version: 'v1' };
      let previousDay = -1;
      let currentPos = 0;
      let tripDetails = { days: [] };

      this.updateLoadingText('Shortlisting Places for your trip...');
      this.generalService.generate(
        data,
        (chunk) => {
          console.log(
            '================================================CHUNK================================================'
          );
          console.log(chunk.toString());
          const objArray = this.parseChunk(chunk);
          objArray.forEach((obj) => {
            console.log('after obj');
            console.log(obj);
            if (obj.hasOwnProperty('etype')) {
              if (obj.etype === 'DAY') {
                //this.daysFormating(obj);
                const result = this.daysFormatingNew(
                  obj,
                  previousDay,
                  tripDetails,
                  currentPos
                );
                previousDay = result.newPreviousDay;
                tripDetails = result.tripDetails;
                currentPos = result.newCurrentPos;
                console.log('tripDetails>>>>>>>>');
                console.log(tripDetails);
                this.tripDays = tripDetails['days'];
                if (previousDay !== -1) {
                  this.updateLoadingText(
                    'Planning your Day ' + previousDay,
                    'move_top'
                  );
                }
              } else if (obj.etype === 'HUB') {
                this.tripDetails['hubs'].push(obj);
              } else if (obj.etype == 'META') {
                this.updateLoadingText('Planning your trip');
                this.tripTitle = obj.hasOwnProperty('trip_title')
                  ? obj.trip_title
                  : '';
                this.tripStory = obj.hasOwnProperty('trip_story')
                  ? obj.trip_story
                  : '';
              }
            } else if (obj.hasOwnProperty('response')) {
              console.log('inside response');
              this.reRenderItinerary(obj);
            }
            if (obj.hasOwnProperty('done')) {
              this.createEditableTrip(tripurl);

              //this.calculateBoxWidth();
              let createEditTrip = true;
              if (
                typeof this.trip === 'object' &&
                !Array.isArray(this.trip) &&
                this.trip !== null
              ) {
                if (this.trip.hasOwnProperty('trip_title')) {
                  this.tripTitle = this.trip['trip_title'];
                }
                if (this.trip.hasOwnProperty('trip_story')) {
                  this.tripStory = this.trip['trip_story'];
                }
                if (this.trip.hasOwnProperty('trip_details')) {
                  if (
                    this.trip['trip_details'].hasOwnProperty('ai_editable_trip')
                  ) {
                    if (this.trip['trip_details']['ai_editable_trip']) {
                      console.log(
                        'Editable ai trip inside value: ' +
                          this.trip['trip_details']['ai_editable_trip']
                      );
                      createEditTrip = false;
                    }
                  }
                }
              }
              console.log('before createEditableTrip');

              this.notificationStructure.type = NotificationEvent.TripCreation;
              this.notificationStructure.message = 'Trip Created';
              this.notificationStructure.result = true;
              this.notification.emit(this.notificationStructure);
              //this.tripDetails['days'].push(this.oneDay);
              setTimeout(() => {
                console.log('INSIDE DONE....');
                this.updateLoadingText(
                  'Your trip is almost ready... ',
                  'move_top'
                );
              }, 100);
            }
          });
        },
        (error) => console.error('Error:', error)
      );
    }
  }

  getTrip(tripurl: string) {
    this.resetTripVariables();
    console.log('getting......');
    const apiUrl = 'trip/getInitialTrip/' + tripurl;
    this.generalService.getMethod(apiUrl).subscribe(
      (value: any) => {
        console.log('EDITABLE TRIP');
        console.log(value);
        if (value['result'] == 'yes') {
          this.reRenderItinerary(value);
        } else {
          this.notificationStructure.type = NotificationEvent.TripNotFound;
          this.notificationStructure.message = 'Trip Not Found';
          this.notificationStructure.result = false;
          this.notification.emit(this.notificationStructure);
        }
      },
      (error: any) => {
        this.notificationStructure.type = NotificationEvent.NotAuthorized;
        this.notificationStructure.message = 'Need To Login';
        this.notificationStructure.result = false;
        this.notification.emit(this.notificationStructure);

        console.log('there is an error in this');
        console.log(error);
      }
    );
  }
  daysFormatingNew(
    day: any,
    previousDay: number,
    tripDetails: any,
    currentPos: number,
    daysLength: number = 0
  ) {
    let newPreviousDay = previousDay;
    let newCurrentPos = currentPos;
    if (!tripDetails.hasOwnProperty('transit_list')) {
      tripDetails['transit_list'] = [];
    }
    if (day.hasOwnProperty('dayNumber')) {
      console.log('day number', day.dayNumber);
      console.log('Previous', previousDay);
      if (previousDay !== parseInt(day['dayNumber'])) {
        if (this.firstDayNumber == null) {
          this.firstDayNumber = parseInt(day['dayNumber']);
          previousDay = parseInt(day['dayNumber']);
        } else {
          newCurrentPos++;
        }

        const dayObj = { days: [], details: {} };
        tripDetails.days[newCurrentPos] = dayObj;
      }
      console.log('Current pos : ' + currentPos);
      console.log('newCurrentPos>>>>' + newCurrentPos);
      console.log('tripDetails');
      console.log(tripDetails);

      if (!tripDetails.days[newCurrentPos]) {
        tripDetails.days[newCurrentPos] = { days: [], details: {} };
      } else if (!tripDetails.days[newCurrentPos].hasOwnProperty('details')) {
        tripDetails.days[newCurrentPos] = { days: [], details: {} };
      }
      newPreviousDay = parseInt(day['dayNumber']);
      tripDetails.days[newCurrentPos].details['dayNumber'] = newPreviousDay;
      if (day.hasOwnProperty('arrival_date_time')) {
        tripDetails.days[newCurrentPos].details['date_time'] =
          day['arrival_date_time'];
      }
    }
    console.log('type : ' + day['type']);
    console.log('current pos : ' + newCurrentPos);
    if (day['type'] == 'place') {
      this.getTripImages(day);
    }
    if (daysLength > 0 && day['type'] == 'mode') {
      const src = this.allDaysHolder[daysLength - 1];
      const dest = this.allDaysHolder[daysLength + 1];
      day['places'] = {
        source: src,
        dest: dest,
      };
      if (day['uniqueId']) {
        const transitDetail = [
          day['uniqueId'],
          src['name'] + ', ' + src['hub'],
          dest['name'] + ', ' + dest['hub'],
          day['data']['transport_mode'],
        ];
        tripDetails['transit_list'].push(transitDetail);
      }
    }
    console.log('newCurrentPos : ' + newCurrentPos);
    console.log(tripDetails);
    console.log('tripDetails >>>>');
    console.log(tripDetails.days[newCurrentPos]);
    if (!tripDetails.days[newCurrentPos]) {
      console.log('days not defined');
      tripDetails.days[newCurrentPos] = { days: [], details: {} };
    }
    console.log(
      'days length =============================' +
        tripDetails.days[0].days.length
    );
    console.log(day);
    if (tripDetails.days[0].days.length == 0 && day['type'] == 'mode') {
    } else {
      tripDetails.days[newCurrentPos].days.push(day);
      console.log('pushed');
    }

    return { newPreviousDay, tripDetails, newCurrentPos };
  }
  reRenderItinerary(obj: any) {
    console.log('reRenderItinerary');
    if (obj.result == 'yes') {
      this.loadingItinerary = false;
      const tripDetailsObj =
        obj.resultData[0]['trip']['trip_details']['ai_trip'];
      console.log('tripDetails================================');
      console.log(tripDetailsObj);
      this.trip = obj.resultData[0]['trip'];
      if (tripDetailsObj.hasOwnProperty('tripTitle')) {
        this.tripTitle = tripDetailsObj['tripTitle'];
      } else if (this.trip.hasOwnProperty('trip_title')) {
        this.tripTitle = this.trip['trip_title'];
      }
      if (tripDetailsObj.hasOwnProperty('tripStory')) {
        this.tripStory = tripDetailsObj['tripStory'];
      } else if (this.trip.hasOwnProperty('trip_story')) {
        this.tripStory = this.trip['trip_story'];
      }
      this.tripStatus = this.trip.hasOwnProperty('display_status')
        ? this.trip['display_status']
        : 'private';
      let days: any;
      this.tripDetails['hubs'] = tripDetailsObj['hubs'];
      this.firstDayNumber == null;
      let previousDay = -1; // Initialize previousDay appropriately
      let currentPos = 0; // Initialize currentPos appropriately
      let tripDetails = { days: [], transit_list: [] }; // Initialize tripDetails appropriately
      let dayObjLength = 0;
      if (tripDetailsObj.hasOwnProperty('locations')) {
        tripDetailsObj['days'] = tripDetailsObj['locations'];
      }
      this.allDaysHolder = tripDetailsObj['days'];
      tripDetailsObj['days'].forEach((day: any) => {
        console.log('INSIDE DAYS');
        console.log(day);
        const result = this.daysFormatingNew(
          day,
          previousDay,
          tripDetails,
          currentPos,
          dayObjLength
        );
        previousDay = result.newPreviousDay;
        tripDetails = result.tripDetails;
        currentPos = result.newCurrentPos;
        dayObjLength++;
      });
      console.log('tripDetails== NEW NEW================================');
      console.log(tripDetails);
      this.tripDays = tripDetails['days'];

      this.getMoreTransitDetails(tripDetails['transit_list']);
      // tripDetails['days'].forEach((day: any) => {
      //   this.daysFormating(day);
      // });
      this.checkEditable(obj.resultData[0]['trip']['user_id']);
    }
  }

  getMoreTransitDetails(transitList: any) {
    console.log('getMoreTransitDetails');
    console.log(JSON.stringify(transitList));
  }
  async createEditableTrip(tripurl: string, retry: boolean = true) {
    console.log(
      'createEditableTrip call value : ' + environment.createEditableTrip
    );
    if (environment.createEditableTrip) {
      console.log('Creating....');
      const url = 'trip/createEditableTrip/' + tripurl;
      this.generalService.getMethod(url).subscribe(
        (value: any) => {
          console.log('EDITABLE TRIP');
          console.log(value);
          if (value['result'] == 'yes') {
            this.updateLoadingText(
              'Your trip is ready. Get ready to pack your bags.',
              'move_top'
            );
            setTimeout(() => {
              this.loadingItinerary = false;
            }, 3000);
            this.resetTripVariables();
            this.reRenderItinerary(value);
          } else {
            this.updateLoadingText(
              'Your trip is ready. Get ready to pack your bags.',
              'move_top'
            );
            setTimeout(() => {
              this.loadingItinerary = false;
            }, 3000);
          }
        },
        (error: any) => {
          if (retry) {
            this.createEditableTrip(tripurl, false);
          }
          this.updateLoadingText(
            'Your trip is ready. Get ready to pack your bags.',
            'move_top'
          );
          setTimeout(() => {
            this.loadingItinerary = false;
          }, 3000);
          console.log('there is an error in this');
          console.log(error);
        }
      );
    } else {
      this.loadingItinerary = false;
    }
  }

  scrollToDay(day: number) {
    const elementId = 'dayNumber' + day;
    console.log('elementId+');
    const element = this.dayElements.find(
      (el) => el.nativeElement.id === elementId
    );
    if (element) {
      element.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  daysDropDownToggle() {
    //this.daysDropDown = !this.daysDropDown;
  }
  parseChunk(chunk: string): any[] {
    try {
      console.log('parsing chunk....');
      // Ensure all objects are separated by a newline or space for easier splitting
      chunk = chunk.replace(/}{/g, '}\n{');

      // Split the chunk by newlines and remove any empty lines
      const chunkArray = chunk.split('\n').filter((line) => line.trim() !== '');

      // Parse each line as a JSON object and collect them into an array
      const jsonArray = chunkArray.map((line) => JSON.parse(line));
      console.log('converting to array.........');
      console.log(jsonArray);
      return jsonArray;
    } catch (error) {
      // Return an empty array if there is a parsing error
      return [];
    }
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'no';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'no';
      default:
        return `${reason}`;
    }
  }
  updateTripStatus(content: TemplateRef<any>) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          const resultResp = { reason: '', action: '', message: '' };
          if (result == 'publish') {
            let trip_status =
              this.tripStatus == 'public' ? 'private' : 'public';
            const url = 'trip/' + this.trip['id'] + '/displayStatus';
            const req = {
              requestMessage: 'getTrip',
              requestId: '108',
              apiKey: '',
              requestData: [
                {
                  tripId: this.trip['id'],
                  displayStatus: trip_status,
                },
              ],
            };
            console.log(url);
            console.log(req);
            this.generalService.putMethod(url, req).subscribe(
              (value) => {
                console.log(value);

                if (value['result'] == 'yes') {
                  this.tripStatus =
                    this.tripStatus == 'public' ? 'private' : 'public';

                  const msg =
                    this.tripStatus == 'public' ? 'published' : 'unpublished';
                  resultResp['action'] = 'toast';
                  resultResp['message'] = 'Trip ' + msg + ' successfully';
                  resultResp['reason'] = 'success';
                  this.notificationStructure.type =
                    NotificationEvent.TripUpdate;
                  this.notificationStructure.result = true;
                  this.notificationStructure.message =
                    'Trip ' + msg + ' successfully';
                } else {
                  const msg =
                    this.tripStatus == 'public' ? 'published' : 'unpublished';
                  this.notificationStructure.type =
                    NotificationEvent.TripUpdate;
                  this.notificationStructure.result = true;
                  this.notificationStructure.message =
                    'Trip ' + msg + ' failed';
                }

                this.notification.emit(this.notificationStructure);
              },
              (error) => {
                console.log('there is an error in this');
                const msg =
                  this.tripStatus == 'public' ? 'published' : 'unpublished';
                this.notificationStructure.type = NotificationEvent.TripUpdate;
                this.notificationStructure.result = true;
                this.notificationStructure.message = 'Trip ' + msg + ' failed';
              }
            );
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }

  async share() {
    const tripUrl = window.location.href;
    let shareReq: any = {
      title: this.tripTitle,
      text: this.tripTitle + ' \n' + this.tripStory,
      url: tripUrl,
    };
    console.log(shareReq);
    if (this.tripImages.length > 0) {
      const img =
        'https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/' +
        this.tripImages[0]['img'];
      const response = await fetch(img);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
      shareReq.files = [file];
    }
    if (navigator.share) {
      navigator
        .share(shareReq)
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {
      const copiedText =
        this.tripTitle + '\n' + this.tripStory + '\n' + tripUrl;
      this.copyToClipboard(copiedText);
    }
  }

  deletetrip(content: TemplateRef<any>) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          const resultResp = { reason: '', action: '', message: '' };
          if (result == 'delete') {
            console.log('DELETED');
            // console.log(this.logindata);
            const url = 'trip/' + this.trip['id'];
            // console.log(JSON.stringify(req));

            this.generalService.deleteMethod(url).subscribe(
              (value: any) => {
                // console.log(value);
                const msg = value.resultData[0].message;
                if (value.result === 'yes') {
                  this.notificationStructure.type =
                    NotificationEvent.TripDeleted;
                  this.notificationStructure.result = true;
                  this.notificationStructure.message =
                    'Trip deleted successfull';

                  this.notification.emit(this.notificationStructure);
                } else {
                  this.notificationStructure.type =
                    NotificationEvent.TripDeleted;
                  this.notificationStructure.result = false;
                  this.notificationStructure.message = 'Trip deletion failed';

                  this.notification.emit(this.notificationStructure);
                }
              },
              (error) => {
                console.log('there is an error in this');
              }
            );
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }

  copyToClipboard(url: string) {
    const textarea = document.createElement('textarea');
    textarea.value = `${url}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('Link copied to clipboard');
  }
}
