<div class="type_data">
  <div class="trip-scrap d-flex px-0 flex-column">
    <div *ngIf="reason != ''" class="reason-text mb-2">
      {{ reason }}
    </div>
    <div class="d-flex">
      <div class="col-4 px-0 mr-3" *ngIf="image != ''">
        <app-image
          *ngIf="image != ''"
          [image]="image"
          [ratio]="'1:1'"
        ></app-image>
      </div>
      <div class="px-0" [ngClass]="image != '' ? 'col-8' : 'col-12'">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="d-flex w-100">
            <div class="custom-tooltip-container">
            <span class="content-heading scrap-title">
              <i class="fas {{ categoryIcon }} iti-icon-ht mr-2"></i>
              <a
                class="pointer"
                *ngIf="placeUrl != ''"
                [routerLink]="['/scrapinner', placeUrl]"
                target="_blank"
                >{{ place["name"] }}</a
              >
              <span *ngIf="placeUrl == ''">{{ place["name"] }}</span>
            </span>
          </div>
          </div>
        </div>
        
          <div
          *ngIf="place_address"
          class="scrap-iti-details place_address d-flex mt-1 align-items-center"
        >
          {{ place_address }}
        
        </div>
        <div
          class="scrap-iti-details small-heading d-flex mt-1 align-items-center"
        >
          <div class="scrap_arrival" *ngIf="arrivalTime">
            <span class="small-heading mr-2">Arr: </span>
            <span>{{ arrivalTime }}</span>
          </div>
          <div
            class="scrap_duration ml-2 small-heading"
            *ngIf="stayDuration !== '' && category!=='hotel'"
          >
            (<i class="far fa-clock"></i> {{ stayDuration }})
          </div>
          <!-- <div>({{timezone}})</div> -->
        </div>
       
      </div>
      <!-- <div class="trip-scrap-more-option">
        <i class="fa fa-ellipsis-v"></i>
      </div> -->
    </div>
    <div *ngIf="placeHighlight !== ''">
      <div class="spacer-1"></div>
      <div class="content-body text-justify" [innerHTML]="placeHighlight"></div>
    </div>
  </div>
</div>
